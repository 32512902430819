<template>
    <div style="width: 100%;min-height:100vh;">
        <div style="width: 100%;background: #FFFFFF">
            <div style="width: 1500px;height: 80px;display: flex;align-items: center;justify-content: space-between;margin: 0 auto;background: #FFFFFF" >
                <div style="display: flex;align-items: center;align-items: center">
                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/bai_log.png" style="height: 46px;width: auto;margin: 0 10px;"/>
                    <div style="color: #031F88;font-size: 30px;font-weight: bold"> | 我的红包问答</div>

                    <div style="width: 600px;background: #FFFFFF"></div>
                </div>
                <div style="display: flex;align-items: center;">
                    <AvatarMenu></AvatarMenu>
                </div>
            </div>
        </div>
        <div style="display: flex;justify-content: center">
            <div style="width: 1500px;background-color: #FFFFFF;min-height: 828px;margin-top: 10px;">
                <div v-for="(item,index) in active_list" :key="index"  style="float: left;margin-left: 40px;margin-top: 20px;display: flex">
                    <div @click="selectedswitch(item.type)" :style="selected == item.type?'color:#2970FF;':''">
                        <div style="display: inline-block;">
                            <div :style="item.type == 1?'width: 72px':'width: 110px'" style="height: 21px; font-size: 18px;cursor: pointer; text-align: left;">{{ item.label }}</div>
                        </div>
                        <div v-if="selected == item.type" :style="selected == 1?'width: 72px':'width: 110px'" style="height: 5px; background: #2970FF; border-radius: 4px; margin: 5px auto;"></div>
                    </div>
                </div>
                <div style="width: 100%;margin-top: 53px;"><el-divider></el-divider></div>
                <div style="margin-left: 40px;display: flex">
                    <div style="">
                        <el-select v-model="seleswitch" placeholder="请选择" @change="selectwitch" style="box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.16);border-radius: 5px">
                            <el-option
                                v-for="item in ListType"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div style="width: 1008px;margin-left: 20px;">
                        <el-input placeholder="请输入关键词搜索" style="box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.16);border-radius: 5px" v-model="searchfor">
                            <template slot="append"><el-button @click="qaSearch" type="primary" style="background: #2970FF;color: #FFFFFF;border-radius:0 5px 5px 0;height: 40px;"><i class="el-icon-search" style="font-size: 15.5px;"></i></el-button></template>
                        </el-input>
                    </div>
                    <div style="margin-left: 20px;">
<!--                        <el-dropdown>-->
<!--                             <el-button type="primary" style="background: #2970FF;">发布红包问题<i class="el-icon-caret-bottom"></i></el-button>-->
<!--                                <el-dropdown-menu slot="dropdown">-->
<!--                                    <div @click="openQuesEdidor(1)"><el-dropdown-item icon="el-icon-platform-eleme">红包问答</el-dropdown-item></div>-->
<!--                                    <div @click="openQuesEdidor(2)"><el-dropdown-item icon="el-icon-coin">悬赏资料</el-dropdown-item></div>-->
<!--                                    <div @click="openQuesEdidor(3)"><el-dropdown-item icon="el-icon-chat-line-round">悬赏讨论</el-dropdown-item></div>-->
<!--                                    <div @click="openQuesEdidor(4)"><el-dropdown-item icon="el-icon-s-finance">必选竞标</el-dropdown-item></div>-->
<!--                                </el-dropdown-menu>-->
<!--                        </el-dropdown>-->
                        <el-switch
                            style="margin-top: 10px;"
                            inactive-text="悬赏"
                            v-model="witch"
                            @change="handleisReward">
                        </el-switch>
                    </div>
                </div>
                <el-table
                    :data="searchList"
                    style="width: 100%;margin-top: 5px;height: 650px;">
                    <el-table-column width="130"  class="no-header">
                        <template slot-scope="scope">
                            <div style="width: 100%;overflow: hidden;margin-left: 30px">
                                <el-tag v-if="scope.row.rewardType == 1" style="background: #DD0D04;color: #FFFFFF;float: left;border: 1px solid #DD0D04;border-radius: 0px;height: 30px;line-height: 27px;font-size: 15px;">红包问答</el-tag>
                                <el-tag v-if="scope.row.rewardType == 2" style="background: #FE9544;color: #FFFFFF;float: left;border: 1px solid #F88450;border-radius: 0px;height: 30px;line-height: 27px;font-size: 15px;">悬赏资料</el-tag>
                                <el-tag v-if="scope.row.rewardType == 3" style="background: #40AC97;color: #FFFFFF;float: left;border: 1px solid #40AC97;border-radius: 0px;height: 30px;line-height: 27px;font-size: 15px;">悬赏讨论</el-tag>
                                <el-tag v-if="scope.row.rewardType == 4" style="background: #9079C1;color: #FFFFFF;float: left;border: 1px solid #9079C1;border-radius: 0px;height: 30px;line-height: 27px;font-size: 15px;">必选竞标</el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="970">
                        <template slot-scope="scope"  class="no-header">
                            <div style="display: flex">
                                <div @click="b('/index/page/?id='+scope.row.id)" style="font-size: 18px;float: left;cursor: pointer;font-weight: 600;max-width: 864px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis">{{scope.row.title}}</div>
                                <div v-if="scope.row.rewardMoney > 0"><img src="../../../assets/imgs/index/release.png" style="width: 16px;height: 18px;margin-top: 3px;margin-left: 10px;"></div>
                                <div v-if="scope.row.rewardMoney > 0" style="color:#FF5E5E;font-size: 18px;margin-left:5px;font-weight: 600;">{{scope.row.rewardMoney}}元</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="100"  class="no-header">
                        <template slot-scope="scope">
                            <span style="font-size: 18px;color: #999999;">{{scope.row.reviewCount}}回答</span>
                        </template>
                    </el-table-column>
                    <el-table-column width="200"  class="no-header">
                        <template slot-scope="scope">
                            <span style="font-size: 18px;color: #999999;">{{scope.row.updateTime}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column width="100"  class="no-header">
                        <template slot-scope="scope">
                            <span v-if="user.uuid == scope.row.uuid" style="font-size: 18px;color: #2970FF;font-weight: 600;cursor: pointer" @click="deleteQuestion(scope.row.id)">删除</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div v-if="searchCount > 0" style="display: flex;justify-content: center;margin-top: 10px;">
                    <div style="margin: 0px auto">
                        <el-pagination
                            background
                            layout="prev, pager, next"
                            :page-size="6"
                            :total="searchCount"
                            @current-change="handleCurrentChange">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog :close-on-click-modal="false" :visible.sync="dialogShow" :append-to-body="true" width="960px" clcaa="begin">
            <Askquestion v-if="dialogShow" @close="hideChild" :awType="shop"  :form="1"></Askquestion>
        </el-dialog>
    </div>
</template>
<script>
import AvatarMenu from "../AvatarMenu";
import Askquestion from "../Askquestion";
export default {
    name: "ask",
    components: {AvatarMenu,Askquestion},
    data() {
        return {
            active_list:[
                // {"label":"全部问题","type":"1"},
                {"label":"我发布的问题","type":"2"},
                {"label":"我回答的问题","type":"3"},
            ],
            selected:'2',
            ListType: [
                { id: 0, label: "全部",},
                { id: 1, label: "红包问答",  },
                { id: 2, label: "悬赏资料",},
                { id: 3, label: "悬赏讨论", },
                { id: 4, label: "比选竞标", },
            ],
            seleswitch:'',
            searchfor:'',
            dialogShow :false,
            searchList:[],
            witch: '0',
            page: 1,
            pageSize:11,
            searchCount:''
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        },
    },
    created: function () {

    },
    mounted:function(){
        this.qaSearch()
    },
    methods: {
        selectwitch(){
            this.qaSearch();
        },
        selectedswitch(name) {
            this.selected = name;
            this.qaSearch()
        },
        handleCurrentChange(val) {
            this.page = val;
            this.qaSearch();
        },
        qaSearch:function(){
            var that = this;
            var param = {};
            param.type = that.selected;
            param.rewardType = that.seleswitch;
            param.like = that.searchfor;
            if (that.witch) {
                param.isReward = 1;
            } else {
                param.isReward = 0;
            }
            param.page = that.page;
            param.pageSize = that.pageSize;
            that.newApi.qaSearch(param).then((res)=>{
                if (res.isSuccess == 1){
                    that.searchList = res.data.list;
                    that.searchCount = res.data.count;
                    that.searchfor = ""
                }
            }).catch((err)=>{
                console.log(err)
            })
        },
        handleisReward(){
            this.qaSearch();
        },

        openQuesEdidor(type){
            var name = ["","红包问答","悬赏资料","悬赏讨论","比标竞选"];
          if (!this.utils.toAuth(1)) {
            return false
          }
                this.shop = type.toString();
                this.dialogShow = true;
        },
        hideChild(){
            this.dialogShow = true;
        },
        deleteQuestion(id){
            let that = this;
            if (that.searchList.reviewCount !== 0) {
                that.newApi.deleteQuestion({
                    id:id
                }).then((res)=>{
                    if (res.isSuccess == 1){
                        that.utils.sus(res.data)
                        that.qaSearch();
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            }else {
               this.utils.err('有人回答不可删除')
            }
        }

    }
}
</script>
<style scoped>
/deep/ .el-switch__label *{
    font-size: 20px;
    font-weight: bold;
}
/deep/ .el-switch__core {
    box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.16);
}
</style>
<!--<style scoped>-->
<!--.menu:hover{-->
<!--    background:#EFEFEF;height:100%;-->
<!--}-->
<!--*{-->
<!--    font-size: 14px;-->
<!--}-->
<!--a{-->
<!--    text-decoration:none; color:rgb(166, 166, 166);-->
<!--}-->
<!--.head_white{-->
<!--    height: 26px;-->
<!--    padding: 5px 0;-->
<!--    width: 90px;-->
<!--    display: flex;-->
<!--    justify-content: center;-->
<!--    align-items: center;-->
<!--}-->
<!--.head_white_word{-->
<!--    width: 90px;-->
<!--    height: 26px;-->
<!--    text-align: center;-->
<!--    line-height:50px;-->
<!--    font-size: 16px;-->
<!--    float: left;-->
<!--    /*font-family: "Microsoft YaHei", Arial, sans-serif;*/-->
<!--    font-family: "Microsoft YaHei", Arial, sans-serif;-->
<!--    font-weight: bold;-->
<!--    color: #FFFFFF;-->
<!--    display: flex;-->
<!--    justify-content: center;-->
<!--    align-items: center;-->
<!--}-->
<!--.tab{-->
<!--    text-align: center;-->
<!--    margin: auto 55px;-->
<!--    text-align: center;-->
<!--    cursor:pointer;-->
<!--}-->
<!--.up{-->
<!--    -webkit-transform: scale(1);-->
<!--    transform: scale(1);-->
<!--    -webkit-transition: all 0.1s;-->
<!--}-->
<!--.head{-->
<!--    width:100%;-->
<!--    z-index:10;-->
<!--    height: 35px;-->
<!--    margin: 5px auto;-->
<!--}-->

<!--/deep/.text .el-input&#45;&#45;suffix {-->
<!--    font-size: 12px;-->
<!--}-->

<!--/deep/.text .el-input__inner{-->
<!--    border-radius: 23px !important;-->
<!--    padding: 0 25px;-->
<!--    height: 33px;-->
<!--    line-height: 25px;-->
<!--    border: 1px solid rgba(0, 0, 0, 0.35);-->
<!--}-->
<!--/deep/.text .el-input__icon{-->
<!--    height: 35px;-->
<!--    width: 20px;-->
<!--    line-height: 35px;-->
<!--}-->
<!--.el-popper[x-placement^=bottom]{-->
<!--    margin-top: 10px;-->
<!--}-->
<!--.el-popper[x-placement^=bottom].popper__arrow{-->
<!--    display: none;-->
<!--}-->
<!--/deep/.el-divider&#45;&#45;vertical{-->
<!--    margin: 0 3px;-->
<!--}-->
<!--/deep/.text .el-input__icon{-->
<!--    height: 30px;-->
<!--    line-height: 30px;-->
<!--}-->
<!--.triangle {-->
<!--    width: 0;-->
<!--    height: 0;-->
<!--    border-bottom: 41px solid #FFFFFF;-->
<!--    border-left: 59px solid transparent;-->
<!--}-->
<!--.head_white_word.active {-->
<!--    width: 90px;-->
<!--    height: 25px;-->
<!--    border-radius: 20px;-->
<!--    background-color: #FFFFFF;-->
<!--    text-align: center;-->
<!--    line-height: 40px;-->
<!--    font-size: 16px;-->
<!--    font-family: "Microsoft YaHei", Arial, sans-serif;-->
<!--    font-weight: bold;-->
<!--    color: #031F88;-->
<!--    display: flex;-->
<!--    justify-content: center;-->
<!--    align-items: center;-->

<!--}-->
<!--/deep/ [class*=" el-icon-"], [class^=el-icon-] {-->
<!--    font-size: 16px;-->
<!--    margin: 2px;-->

<!--}-->
<!--/deep/ .event-form .el-dialog__header {-->
<!--    padding: 0;-->
<!--}-->

<!--/deep/ .event-form .el-dialog__body {-->
<!--    padding: 0;-->
<!--}-->

<!--/deep/ .event-form .el-dialog {-->
<!--    margin-bottom: 10px;-->
<!--}-->

<!--/deep/.el-dialog__header {-->
<!--    padding: 30px 20px 10px;-->
<!--}-->
<!--/deep/ .el-dialog__body{-->
<!--    padding: 0px 0px;-->
<!--}-->
<!--</style>-->

<!--<style>-->
<!--.upload_att .el-dialog__body {-->
<!--    padding: 28px 2px !important;-->
<!--}-->
<!--.upload_att .el-dialog__header {-->
<!--    padding: 0px !important;-->
<!--}-->
<!--.begin .el-dialog__header {-->
<!--    padding: 30px 20px 10px;-->
<!--}-->
<!--.begin .el-dialog__body {-->
<!--    padding: 0px 0px;-->
<!--}-->
<!--</style>-->
